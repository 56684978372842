import { variation } from 'ember-launch-darkly';

import ApplicationAbility from './application';

export default class MemberAbility extends ApplicationAbility {
  get isOrgaRegistered() {
    return !this.organization.underRegistration;
  }

  get canAccess() {
    return this.permissions.memberships.access && this.isOrgaRegistered;
  }

  get canView() {
    return this.permissions.memberships.read;
  }

  get canAccessManagerRoleFeature() {
    return Boolean(this.pricePlanFeatures.managerRole);
  }

  get canCreatePaidMembers() {
    return Boolean(this.pricePlanFeatures.additionalUsers);
  }

  get canNavigate() {
    return this.canAccess;
  }

  get canAddAdminOnly() {
    return (
      variation('feature--boolean-modular-pricing-admins-for-solo') &&
      this.subscriptionManager.getLimitByFeatureCode('additional_users') === 1 &&
      !this.pricePlanFeatures.teamManagement
    );
  }
}
