/* eslint-disable @qonto/no-import-roles-constants */
import { service } from '@ember/service';

import { ROLES } from 'qonto/constants/membership';

import ApplicationAbility from './application';

export default class AccountingHubAbility extends ApplicationAbility {
  @service userManager;
  @service organizationManager;

  get reportingRolesCount() {
    let memberships = this.userManager.currentUser.memberships;
    return memberships.filter(({ role }) => role === ROLES.REPORTING).length;
  }

  get canAccess() {
    return Boolean(this.reportingRolesCount);
  }

  get canAccessOrganization() {
    return this.permissions.accounting_hub.access;
  }

  get canAccessIntegrations() {
    return (
      this.organizationManager.organization.legalCountry === 'FR' && this.reportingRolesCount >= 3
    );
  }
}
