import { service } from '@ember/service';

import ApplicationAbility from './application';

export default class NrcAbility extends ApplicationAbility {
  @service featuresManager;

  get canView() {
    let isSpanishOrganization = this.organization.legalCountry === 'ES';
    return (
      isSpanishOrganization &&
      this.permissions.nrc.read &&
      this.featuresManager.isEnabled('nrcPayments')
    );
  }

  get canNavigate() {
    return this.canView;
  }
}
