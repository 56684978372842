import ApplicationAbility from './application';

export default class ConnectAbility extends ApplicationAbility {
  get canView() {
    return (
      this.featuresManager.isEnabled('hub') &&
      this.permissions.integrations.access_marketplace &&
      !this.organization.underRegistration
    );
  }

  get canActivatePremiumIntegration() {
    return Boolean(this.pricePlanFeatures.connectPremium);
  }

  get canAccessSilver() {
    return Boolean(this.pricePlanFeatures.connectIntegrationLevelSilver);
  }

  get canAccessGold() {
    return Boolean(this.pricePlanFeatures.connectIntegrationLevelGold);
  }

  get canNavigate() {
    if (['AT', 'NL', 'PT', 'BE'].includes(this.organization.legalCountry)) return false;
    return this.canView;
  }

  get canAccessEbics() {
    return this.canView && ['FR', 'DE'].includes(this.organization.legalCountry);
  }

  canAccessIntegration(minimumIntegrationLevel) {
    if (!minimumIntegrationLevel) return true;

    return this.subscriptionManager.hasFeature(minimumIntegrationLevel);
  }
}
