import { InvalidError } from '@ember-data/adapter/error';
import { waitFor } from '@ember/test-waiters';

import { adapterAction } from '@mainmatter/ember-api-actions';

import ApiAdapter from 'qonto/adapters/base/api';
import { transferNamespace } from 'qonto/constants/hosts';

export default class extends ApiAdapter {
  namespace = transferNamespace;

  handleResponse(status, headers, payload) {
    // this ensures we don't go through the `errorsHashToArray()` call of the
    // active-model-adapter if the `errors` object content is unrelated to the
    // attributes of the model.
    if (status === 422 && payload.errors && payload.errors.code && payload.errors.message) {
      let error = new InvalidError([payload.errors]);
      error.code = payload.errors.code;
      return error;
    }

    return super.handleResponse(...arguments);
  }

  @waitFor
  async fxMarket() {
    return await adapterAction(this, 'transfer', {
      method: 'GET',
      path: 'fx/market',
    });
  }

  @waitFor
  async checkFxMarketIsOpen(data) {
    return await adapterAction(this, 'transfer', {
      method: 'POST',
      path: 'fx/market/available',
      data,
    });
  }

  @waitFor
  async getTransferRequirements(beneficiaryId) {
    return await adapterAction(this, 'transfer', {
      method: 'GET',
      path: `fx/requirements?beneficiary_id=${beneficiaryId}`,
    });
  }
}
