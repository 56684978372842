import { requestsNamespace } from 'qonto/constants/hosts';

import ApplicationAdapter from './application';

export default class RequestAdapter extends ApplicationAdapter {
  namespace = requestsNamespace;
  handleResponse(status, headers, payload, requestData) {
    payload.requests?.forEach(request => {
      request.type = `request_${request.request_type}`;
    });

    return super.handleResponse(status, headers, payload, requestData);
  }
}
