import { InvalidError } from '@ember-data/adapter/error';
import { service } from '@ember/service';

import { variation } from 'ember-launch-darkly';

import JSONAPIAdapter from 'qonto/adapters/base/json-api';
import { receivableCreditNoteNamespace } from 'qonto/constants/hosts';

export default class ReceivableCreditNotesAdapter extends JSONAPIAdapter {
  @service organizationManager;
  namespace = receivableCreditNoteNamespace;

  pathForType() {
    return 'receivable_invoices/credit_notes';
  }

  handleResponse(status, headers, payload, requestData) {
    if (status === 409) {
      let { errors } = payload;
      if (errors.some(e => e.code === 'credit_note_number_already_exists')) {
        errors.forEach((e, index, errors) => {
          if (e.code === 'credit_note_number_already_exists') {
            errors[index] = { ...e, source: { pointer: '/data/attributes/number' } };
          }
        });
        let error = new InvalidError(errors);
        error.status = status;
        error.url = requestData.url;
        error.method = requestData.method;
        return error;
      }
    }
    return super.handleResponse(status, headers, payload, requestData);
  }

  urlForPdf(id) {
    let url;
    if (variation('feature--boolean-import-ar')) {
      url = new URL(`${super.urlForFindRecord(id, 'receivable-credit-note')}/file`);
    } else {
      url = new URL(`${super.urlForFindRecord(id, 'receivable-credit-note')}/pdf`);
    }
    url.searchParams.append('organization_id', this.organizationManager.organization.id);
    return url.toString();
  }

  sendUrl(id) {
    let url = new URL(`${super.urlForFindRecord(id, 'receivable-credit-note')}/send`);
    return url.toString();
  }
}
