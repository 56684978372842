import { service } from '@ember/service';

import { variation } from 'ember-launch-darkly';
import { reads } from 'macro-decorators';

import ApplicationAbility from './application';

export default class CardAbility extends ApplicationAbility {
  @service featuresManager;
  @service subscriptionManager;
  @service organizationManager;

  @reads('pricePlanFeatures.cardRestrictiveCategories') canViewRestrictiveCategories;
  @reads('pricePlanFeatures.advertisingCard') canViewAdvertising;
  @reads('pricePlanFeatures.flashCard') canViewFlash;
  @reads('pricePlanFeatures.additionalUsers') isTeamPlan;
  @reads('pricePlanFeatures.cardAdvancedSettings') canViewRestrictions;
  @reads('isCardHolder') canSee;
  @reads('featuresManager.flags.cardAbroadOption') canEnableAbroadOption;

  get canRevealPAN() {
    return this.isCardHolder && this.model.isOperational;
  }

  get canRead() {
    return this.permissions.cards.read;
  }

  get canNavigate() {
    return this.canRead;
  }

  get canNavigateTeam() {
    let { admin, owner, manager } = this.organizationManager.membership;
    return Boolean(this.isTeamPlan && (admin || owner || manager));
  }

  get canUpdateSettings() {
    return this.permissions.cards.updateSettings;
  }

  get canViewPaymentLimitsDisclaimer() {
    return (
      this.isCardHolder &&
      this.permissions.cards.updateSettings &&
      (this.model.isOperational || this.model.pending)
    );
  }

  get canUpsellRenewal() {
    if (
      variation('operational--boolean-disable-card-creation-for-italy') &&
      this.organizationManager.organization.legalCountry === 'IT'
    )
      return false;

    return this.permissions.cards.upsellRenewal;
  }

  get canUpdateOperationalCardSettings() {
    return this.permissions.cards.updateSettings && this.model.isOperational;
  }

  get canUpdateNickname() {
    return this.permissions.cards.updateNickname && this.model.isOperational;
  }

  get isCardHolder() {
    return this.get('model.holder.id') === this.membership.id;
  }

  get canCreate() {
    return this.permissions.cards.create;
  }

  get canReportLostOrStolen() {
    return this.model.isReportableAsLostOrStolen;
  }

  get canDelete() {
    return this.model.isOperational && this.permissions.cards.delete;
  }

  get canResetPIN() {
    return this.isCardHolder && this.model.isPinResetable;
  }

  get canSetPIN() {
    return this.isCardHolder && this.model.isPinSettable;
  }

  get canSeeEstimatedDeliveryDate() {
    return (
      this.isKYBAccepted &&
      this.organizationManager.organization.legalCountry === 'FR' &&
      this.get('model.holder.kycStatus') === 'accepted'
    );
  }

  get canUnlock() {
    return this.model.paused && this.permissions.cards.unlock;
  }

  get canLock() {
    return this.model.live && this.permissions.cards.lock;
  }

  get canSeePIN() {
    return this.isCardHolder && this.model.isPhysical && this.model.isOperational;
  }

  get canRenew() {
    return this.permissions.cards.renew;
  }

  // Can upsell any card eligible for upsell
  get canUpsellAny() {
    if (
      variation('operational--boolean-disable-card-creation-for-italy') &&
      this.organizationManager.organization.legalCountry === 'IT'
    )
      return false;

    return this.permissions.cards.upsell;
  }

  get canUpsell() {
    return this.canUpsellAny && this.isCardHolder && this.model.eligibleForUpsell;
  }

  // TODO cleanup this whenever XPay is enabled for all markets (expected Janv2025 or sooner)
  get canSeeXPay() {
    return (
      !this.organizationManager.organization.isGeoExpansionLegalCountry ||
      !variation('feature--boolean-geo_expansion_x_pay_disabled')
    );
  }

  get canAccessCreationFlow() {
    let isItalyBan =
      variation('operational--boolean-disable-card-creation-for-italy') &&
      this.organizationManager.organization.legalCountry === 'IT';
    return this.canCreate && !isItalyBan;
  }
}
