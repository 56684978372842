import ApplicationAbility from './application';

export default class ApprovalWorkflowAbility extends ApplicationAbility {
  get canSeeState() {
    return (
      Boolean(this.pricePlanFeatures.requests) &&
      this.featuresManager.isEnabled('approvalWorkflows')
    );
  }

  get canRead() {
    return (
      Boolean(this.pricePlanFeatures.requests) &&
      this.featuresManager.isEnabled('approvalWorkflows') &&
      this.permissions.approval_workflows.read
    );
  }

  get canCreate() {
    return (
      Boolean(this.pricePlanFeatures.requests) &&
      this.featuresManager.isEnabled('approvalWorkflows') &&
      this.permissions.approval_workflows.create
    );
  }

  get canUpdate() {
    return (
      Boolean(this.pricePlanFeatures.requests) &&
      this.featuresManager.isEnabled('approvalWorkflows') &&
      this.permissions.approval_workflows.edit
    );
  }
}
