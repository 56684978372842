import { membershipAllowedBankAccountsNamespace, membershipNamespace } from 'qonto/constants/hosts';
import { requestTypes } from 'qonto/models/membership';

import ApplicationAdapter from './application';

export default class MembershipAdapter extends ApplicationAdapter {
  namespace = membershipNamespace;

  buildURL(_, __, ___, requestType) {
    let url = super.buildURL(...arguments);
    if (
      requestType === requestTypes.getAllowedBankAccounts ||
      requestType === requestTypes.updateAllowedBankAccounts
    ) {
      return url.replace(
        `${this.host}/${this.namespace}`,
        `${this.host}/${membershipAllowedBankAccountsNamespace}`
      );
    }
    return url;
  }
}
