import { service } from '@ember/service';

import { ENABLED_LEGAL_COUNTRIES } from 'qonto/constants/insurance-hub';

import ApplicationAbility from './application';

export default class InsuranceContractAbility extends ApplicationAbility {
  @service organizationManager;

  get canRead() {
    return (
      this.permissions.insurance_contracts.read &&
      ENABLED_LEGAL_COUNTRIES.includes(this.organizationManager.organization.legalCountry)
    );
  }
}
