import RestAdapter from '@ember-data/adapter/rest';
import { service } from '@ember/service';

import { apiBaseURL, supplierHubNamespace } from 'qonto/constants/hosts';

export default class SupplierAdapter extends RestAdapter {
  @service networkManager;

  namespace = supplierHubNamespace;
  host = apiBaseURL;

  get headers() {
    return this.networkManager.requestHeaders;
  }

  shouldReloadRecord() {
    return true;
  }

  shouldBackgroundReloadRecord() {
    return false;
  }
}
