import JSONAPIAdapter from '@ember-data/adapter/json-api';

import { companyCreationBaseURL, companyCreationNamespace } from 'qonto/constants/hosts';

export default class CompanyCreationAdapter extends JSONAPIAdapter {
  host = companyCreationBaseURL;
  namespace = companyCreationNamespace;

  ajaxOptions() {
    let options = super.ajaxOptions(...arguments);
    options.credentials = 'include';
    return options;
  }
}
