import JSONAPIAdapter from '@ember-data/adapter/json-api';

import { registerPartnersNamespace, registerPartnersURL } from 'qonto/constants/hosts';

export default class RegisterPartnersAdapter extends JSONAPIAdapter {
  host = registerPartnersURL;
  namespace = registerPartnersNamespace;

  ajaxOptions() {
    let options = super.ajaxOptions(...arguments);
    options.credentials = 'include';
    return options;
  }
}
