import JSONAPIAdapter from 'qonto/adapters/base/json-api';
import { receivableInvoiceV4Namespace } from 'qonto/constants/hosts';

export default class RemindersConfigurationAdapter extends JSONAPIAdapter {
  namespace = receivableInvoiceV4Namespace;

  pathForType() {
    return 'receivable_invoices/reminders';
  }

  ajaxOptions(url, type, options) {
    // PATCH is not supported by the API, so we use PUT instead
    // https://openapi-master.staging.qonto-cbs.co/receivable-invoicing-v4#tag/reminders/operation/update-reminder

    if (type === 'PATCH') {
      type = 'PUT';
    }
    return super.ajaxOptions(url, type, options);
  }

  async createRecord(store, type, snapshot) {
    let result = await super.createRecord(...arguments);
    // remove all local rules to avoid duplicates
    snapshot.record.rules.forEach(rule => rule.unloadRecord());
    return result;
  }

  async updateRecord(store, type, snapshot) {
    let result = await super.updateRecord(...arguments);
    // remove all local rules to avoid duplicates
    snapshot.record.rules.forEach(rule => rule.unloadRecord());
    return result;
  }
}
