import { service } from '@ember/service';
import { waitFor } from '@ember/test-waiters';

import { adapterAction } from '@mainmatter/ember-api-actions';

import ApiAdapter from 'qonto/adapters/base/api';
import { organizationNamespace } from 'qonto/constants/hosts';
import transformKeys from 'qonto/utils/transform-keys';

export default class OrganizationAdapter extends ApiAdapter {
  @service organizationManager;

  namespace = organizationNamespace;

  @waitFor
  buildQuery(snapshot) {
    let query = super.buildQuery(snapshot);
    if (snapshot.include) {
      query.includes = snapshot.include;
      delete query.include;
    }
    return query;
  }

  async listDeactivationReasons() {
    return await adapterAction(this, 'organization', {
      method: 'GET',
      path: 'deactivation_reasons',
    });
  }

  @waitFor
  async getPaymentActivationEligibility() {
    let response = await adapterAction(this, 'organization', {
      method: 'GET',
      path: `${this.organizationManager.organization.id}/payment_activation_topup_eligibility`,
    });

    return transformKeys(response);
  }

  async hasReferrer() {
    let response = await adapterAction(this, 'organization', {
      method: 'GET',
      path: `${this.organizationManager.organization.id}/has_referrer`,
    });

    return transformKeys(response);
  }
}
