import ApplicationAbility from './application';

export default class TransactionAbility extends ApplicationAbility {
  get canRead() {
    return this.permissions.transactions.read;
  }

  get canExport() {
    return this.permissions.transactions.export;
  }

  get canUpdate() {
    return this.permissions.transactions.update;
  }

  get canUpdateAttachmentRequiredStatus() {
    return this.permissions.transactions.updateAttachmentRequiredStatus;
  }

  get canAccessAccountantFeatures() {
    return Boolean(this.pricePlanFeatures.accountantAccess);
  }

  get canQualifyForAccounting() {
    return (
      this.permissions.transactions.qualifyForAccounting &&
      Boolean(this.pricePlanFeatures.transactionsReview)
    );
  }

  canRequestAttachments(transaction) {
    let initiatorId = transaction?.initiatorId;

    if (!initiatorId || initiatorId === this.membership?.id) return false;

    return this.permissions.transactions.requestAttachments;
  }
}
