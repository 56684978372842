import ApplicationAbility from './application';

export default class ClientHubAbility extends ApplicationAbility {
  get canWrite() {
    return this.permissions.clients.write;
  }

  get canRead() {
    return this.permissions.clients.read;
  }
}
