import { variation } from 'ember-launch-darkly';
import { reads } from 'macro-decorators';

import ApplicationAbility from './application';

export default class ReceivableInvoiceAbility extends ApplicationAbility {
  @reads('pricePlanFeatures.receivableInvoices') canFullyAccess;

  get canCreate() {
    return this.permissions.receivableInvoices.create;
  }

  get canNavigate() {
    return !this.organization.isDeactivated && this.canCreate;
  }

  get canRead() {
    return this.permissions.receivableInvoices.read;
  }

  get canUpdate() {
    return this.permissions.receivableInvoices.update;
  }

  get canMatch() {
    return this.canCreate && this.canRead && this.canUpdate;
  }

  get canExport() {
    return this.canRead;
  }

  get canImport() {
    let {
      organization: { legalCountry },
    } = this.organizationManager;

    return variation('feature--boolean-import-ar') && this.canCreate && legalCountry !== 'IT';
  }
}
