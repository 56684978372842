import { waitFor } from '@ember/test-waiters';

import { adapterAction } from '@mainmatter/ember-api-actions';

import ApiAdapter from 'qonto/adapters/base/api';

export default class ApprovalWorkflowAdapter extends ApiAdapter {
  namespace = 'v1';

  @waitFor
  async getApproverOptions(requestType) {
    let response = await adapterAction(this, 'approval-workflow', {
      method: 'GET',
      path: `builder_options?scope=organization&type=${requestType}`,
    });
    return response.options.approvers;
  }

  async updateRecord(store, type, snapshot) {
    let response = await super.updateRecord(store, type, snapshot);
    this.#unloadNestedNewRecords(snapshot.record);
    return response;
  }

  async createRecord(store, type, snapshot) {
    let response = await super.createRecord(store, type, snapshot);
    this.#unloadNestedNewRecords(snapshot.record);
    return response;
  }
  // Saved nested records are not associated with the new unsaved counterparts.
  // We're not using lids so we'll end up with two copies of nested object after save:
  // 1. the new unsaved record without the id
  // 2. the saved record returnd from the server with the id
  // Lets unloaded nested new records, we keep the nested saved records.
  #unloadNestedNewRecords(record) {
    record.rulesets.forEach(ruleset => {
      ruleset.conditions.forEach(this.#unloadNewRecord);
      ruleset.steps.forEach(this.#unloadNewRecord);
      this.#unloadNewRecord(ruleset);
    });
  }

  #unloadNewRecord(record) {
    if (record.isNew) {
      record.unloadRecord();
    }
  }
}
